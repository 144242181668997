import styled from 'styled-components';
import { theme } from '../../constants/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 800;
  font-size: 1rem;
  font-family: ${theme.fonts.headerFont.name}, ${theme.fonts.headerFont.type};
  text-transform: uppercase;
  padding: 20px 5vw;
  letter-spacing: 2px;
  color: ${theme.colors.base};

  span {
    max-width: 40%;
    line-height: 1.5;
  }

  & a {
    color: ${theme.colors.base};
  }
`;
