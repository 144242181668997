import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { theme } from '../../constants/theme';

export const Text = styled.span`
  display: block;
  text-align: ${props => props.align};
  color: ${props => props.color};
  max-width: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '600px';
      case 'giant':
        return '800px';
      default:
        return '600px';
    }
  }};
  margin: ${({ align }) =>
    (align === 'left' && '0 0 30px') ||
    (align === 'bottom' && '0') ||
    (align === 'center' && '0 auto 30px') ||
    '0 0 30px'};
  text-transform: ${({ as }) => () => {
    switch (as) {
      case 'p':
        return 'initial';
      default:
        return 'uppercase';
    }
  }};
  font-weight: 200;
  font-family: ${({ as }) => () => {
    switch (as) {
      case 'p':
        return theme.fonts.bodyFont.name + ', ' + theme.fonts.bodyFont.type;
      case 'h6':
        return theme.fonts.bodyFont.name + ', ' + theme.fonts.bodyFont.type;
      default:
        return 'Silk, serif';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '5rem';
      case 'giant':
        return '10rem';
      case 'small':
        return '2rem';
      default:
        return '3rem';
    }
  }};
  line-height: 1.2;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '3.4rem';
        case 'giant':
          return '4.3rem';
        default:
          return '2rem';
      }
    }};
  `};
`;
