import ProximaNova from '../fonts/ProximaNova-Regular.woff';
import Silk from '../fonts/SilkSerif-Light.woff';

export const theme = {
  colors: {
    bg: '#EBE7E4',
    base: '#8F9F84',
    base2: '#E3C7B1',
    accent: '#000',
    bg2: '#fff',
  },
  fonts: {
    headerFont: {
      url: Silk,
      name: 'Silk',
      type: 'serif',
    },
    bodyFont: {
      url: ProximaNova,
      name: 'ProximaNova',
      type: 'sans-serif',
    },
  },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
};
