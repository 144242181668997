import styled from 'styled-components';
import { theme } from 'constants/theme';

export const Container = styled.div`
  svg {
    width: 100%;
    max-width: 140px;

    .light & path {
      fill: ${theme.colors.bg};
    }
    .dark & path {
      fill: ${theme.colors.base};
    }
  }
`;
