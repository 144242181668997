import React from 'react';
import Icon from '../../images/nest-logo.svg';

import { Container } from './logo.css';
//import logo from '../../../static/nest-logo.svg';

const Logo = () => {
  return (
    <Container>
      <Icon alt="Logo" />
    </Container>
  );
};
export default Logo;
