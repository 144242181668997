import styled from 'styled-components';
import { theme } from 'constants/theme';

export const Hamburger = styled.div`
  background-color: ${theme.colors.base};
  width: 40px;
  height: 1px;
  transition: all 0.2s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};
  .light & {
    background-color: ${theme.colors.bg};
  }

  [data-menu-state='open'] & {
    background-color: ${theme.colors.bg};
  }

  [data-menu-state='open'].light & {
    background-color: ${theme.colors.base};
  }

  ::before,
  ::after {
    width: 40px;
    height: 1px;
    content: '';
    position: absolute;
    transition: all 0.2s linear;
    background-color: ${theme.colors.base};

    .light & {
      background-color: ${theme.colors.bg};
    }

    [data-menu-state='open'] & {
      background-color: ${theme.colors.bg};
    }

    [data-menu-state='open'].light & {
      background-color: ${theme.colors.base};
    }
  }

  ::before {
    transform: ${props =>
      props.open ? 'rotate(-90deg) translate(-8px, 0px)' : 'rotate(0deg)'};
    top: -8px;
  }

  ::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 8px;
  }
`;
