import React from 'react';
import Swiggle from '../../images/swiggle.svg';
import Bath from '../../images/bath.svg';
import Fire from '../../images/fire.svg';
import Instagram from '../../images/instagram.svg';
import Airbnb from '../../images/airbnb.svg';
import { Image } from './icon.css';

export const BathIcon = () => {
  return (
    <Image>
      <Bath />
    </Image>
  );
};

export const FireIcon = () => {
  return (
    <Image>
      <Fire />
    </Image>
  );
};

export const SwiggleIcon = () => {
  return (
    <Image>
      <Swiggle />
    </Image>
  );
};

export const InstagramIcon = () => {
  return (
    <Image>
      <Instagram />
    </Image>
  );
};

export const AirbnbIcon = () => {
  return (
    <Image>
      <Airbnb />
    </Image>
  );
};
