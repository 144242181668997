import styled from 'styled-components';
import { theme } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    ${MEDIA.TABLET`
      flex-direction: column;

    `}

    li {
      text-transform: uppercase;
      font-size: 1.3rem;
      margin-right: 2rem;
      display: inline-flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        a::after {
          transform: scaleX(1);
          transform-origin: right;
        }
      }

      ${MEDIA.TABLET`
      transition: all 1s ease-out;

      [data-menu-state='closed'] & {
        transform: translateX(-40px);
        opacity: 0;
        transition-delay: 0;
        transition: 0s;
      }
      [data-menu-state='open'] & {
        transform: translateX(0);

      }

      &:nth-child(3) {
        transition-delay: 0.4s;
      }
      &:nth-child(4) {
        transition-delay: 0.6s;
      }
      &:nth-child(5) {
        transition-delay: 0.8s;
      }
  `}
    }
  }

  .icon a:after {
    display: none;
  }

  a {
    text-decoration: none;
    position: relative;

    .light & {
      color: ${theme.colors.bg};
    }

    .dark & {
      color: ${theme.colors.base};
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      transform: scaleX(0);
      transition: 0.2s ease-out;
      transform-origin: left;

      .light & {
        background: ${theme.colors.bg};
      }

      .dark & {
        background: ${theme.colors.base};
      }
    }

    ${MEDIA.TABLET`
      font-family: Silk, serif;
      font-size: 4rem;
      line-height: 1;
      margin-bottom: 3rem;

      .light & {
        color: ${theme.colors.base};
      }

      .dark & {
        color: ${theme.colors.bg};
      }
  `}
  }

  svg {
    height: auto;
    width: 1.5rem;

    path {
      .light & {
        fill: ${theme.colors.bg};
      }

      .dark & {
        fill: ${theme.colors.base};
      }
    }
  }
`;
