import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import posed from 'react-pose';
import { Container } from './header.css';
import Logo from 'components/logo';
import Nav from 'components/header/nav';
import { Hamburger } from './hamburger/hamburger.css';
import { Toggle } from './toggle/toggle.css';
import { Navbox } from './navbox/navbox.css';

// Example of a component-specific page transition
/*const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
}); */

const Header = ({ headerTheme }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    //<AnimatedContainer>
    <Container
      className={headerTheme}
      data-menu-state={navbarOpen ? 'open' : 'closed'}
    >
      <Link to="/" className="logo">
        <Logo />
      </Link>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <Nav headerTheme={headerTheme} />
        </Navbox>
      ) : (
        <Navbox open>
          <Nav headerTheme={headerTheme} />
        </Navbox>
      )}
    </Container>
    // </AnimatedContainer>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  headerTheme: PropTypes.string.isRequired,
};

export default Header;
