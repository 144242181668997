import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './title.css';
import RevealText from '../animations/revealText';

const Title = ({ children, as = 'span', size, color, align = 'left' }) => {
  return (
    <Text as={as} size={size} color={color} align={align} className={align}>
      <RevealText>{children}</RevealText>
    </Text>
  );
};

Title.propTypes = {
  children: PropTypes.any.isRequired,
  as: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
};

export default Title;
