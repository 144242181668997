import React from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import { InstagramIcon } from '../../icon';
import { AirbnbIcon } from '../../icon';

const Nav = () => (
  <Container>
    <ul>
      <li className="icon">
        <a
          href="https://www.instagram.com/nestatbluebay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
      </li>
      <li className="icon">
        <a
          href="https://www.airbnb.com.au/rooms/44395601"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AirbnbIcon />
        </a>
      </li>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/things-to-do">Things To Do</Link>
      </li>
      <li>
        <Link to="/gift-vouchers">Gift Vouchers</Link>
      </li>
      <li>
        <Link to="/bookings">Bookings</Link>
      </li>
    </ul>
  </Container>
);

export default Nav;
