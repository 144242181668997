import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from './section.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Section = ({
  children,
  bg,
  padding = '50px 5vw',
  height,
  type = 'grid',
  reverseOnMobile,
  columns = 'repeat(auto-fit, minmax(350px, 1fr))',
  color,
}) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    let header = document.querySelector('header');
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top 20%',
      },
    });
    tl.set(header, { background: { bg } });
  }, []);

  return (
    <Container
      bg={bg}
      padding={padding}
      height={height}
      type={type}
      reverseOnMobile={reverseOnMobile}
      columns={columns}
      color={color}
    >
      {children}
    </Container>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
  padding: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  reverseOnMobile: PropTypes.any,
  columns: PropTypes.string,
  color: PropTypes.any,
};

export default Section;
