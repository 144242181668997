import React from 'react';
import { Container } from './footer.css';

const Footer = () => {
  return (
    <Container>
      <span>All rights reserved © Nest At Blue Bay</span>
      <span>
        <a
          href="https://thisisadvantage.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Website by Advantage
        </a>
      </span>
    </Container>
  );
};

export default Footer;
