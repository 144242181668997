import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 20px 5vw;
  z-index: 5;

  @media (max-width: 768px) {
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`;
