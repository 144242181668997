import styled from 'styled-components';
import { theme } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  ${MEDIA.TABLET`
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 14vh 10vw;
    background-color: ${theme.colors.base};
    transition: all 0.4s ease-out;
    top: -100%;
    left: 0;
    z-index: 10;
    transform: ${props => (props.open ? 'translateY(0)' : 'translateY(100%)')};

    .icon {
      position: absolute;
      bottom: 10vw;
      right: 10vw;
      margin: 0;

      svg {
        width: 30px;
      }

      &:first-child {
        margin-right: 50px;
      }
    }

    .light & {
      background-color: ${theme.colors.bg};
      color: ${theme.colors.base};
    }

    svg path {
      .light & {
        fill: ${theme.colors.base};
      }
      .dark & {
        fill: ${theme.colors.bg};
      }
  }
    `};
`;
