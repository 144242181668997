import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.section`
  padding: ${props => props.padding};
  background: ${props => props.bg};
  min-height: ${props => props.height};
  display: ${props => props.type};
  grid-gap: 8rem;
  grid-template-columns: ${props => props.columns};
  overflow: hidden;
  color: ${props => props.color};

  ${MEDIA.TABLET`
  flex-direction: ${props =>
    props.reverseOnMobile ? 'column-reverse' : 'column'};
  display: ${props => (props.reverseOnMobile ? 'flex' : 'grid')};
  grid-template-columns: auto;
  `}
`;
