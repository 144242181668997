import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: block;
  visibility: hidden;

  .line {
    overflow: hidden;
    display: flex;
    line-height: 1;
    margin-bottom: 0.5rem;

    .center & {
      justify-content: center;
    }
  }
`;
