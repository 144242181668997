import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from './revealText.css';

import gsap, { Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const RevealText = ({ children }) => {
  const revealRef = useRef(null);

  useEffect(() => {
    let text = revealRef.current;

    // Splitting words
    text.dataset.splitText = text.textContent;
    text.innerHTML = text.textContent
      .split(/\s/)
      .map(function(word) {
        return word
          .split('-')
          .map(function(word) {
            return '<span class="word">' + word + '</span>';
          })
          .join('<span class="hyphen">-</span>');
      })
      .join('<span class="whitespace"> </span>');

    // get lines
    let lines = [];
    let line;
    let words = text.children;
    let lastTop;

    Array.from(words).forEach(word => {
      if (word.offsetTop != lastTop) {
        if (!word.classList.contains('whitespace')) {
          lastTop = word.offsetTop;
          line = [];
          lines.push(line);
        }
      }
      line.push(word);
    });

    // splitLines
    let wrappedLines = '';

    lines.forEach(line => {
      wrappedLines += '<span class="line"><div class="mask-up">';
      line.forEach(word => {
        wrappedLines += word.outerHTML;
      });
      wrappedLines += '</div></span>';
    });
    text.innerHTML = wrappedLines;

    lines = text.children;
    let revealElement = [];

    Array.from(lines).forEach(line => {
      revealElement.push(line.children);
    });

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: revealRef.current,
        start: 'top bottom',
      },
    });
    tl.set(text, { autoAlpha: 1 });
    tl.from(revealElement, 1, {
      yPercent: 100,
      stagger: 0.2,
      ease: Power3.out,
      delay: 0.4,
    });
  }, []);

  return <Container ref={revealRef}>{children}</Container>;
};

RevealText.propTypes = {
  children: PropTypes.any,
};

export default RevealText;
